<template>
  <div class="registry-page">
    <PageHeader header="Wedding" />
    <div class="container rsvp__container">
      <div class="row">
        <div class="col-sm-12">
          <template v-if="step == 0">
            <h3 class="text-center mb-5">We'll see you on the soon!</h3>
            <div class="mb-5">
              <p class="text-center">
                September 16, 2023 | 1:00PM - 10:00PM</p>
              <p class="text-center">
                <strong>Ceremony:</strong><br>
                St. John's Roman Catholic Church<br>
                161 Main St, Old Saybrook, CT 06475<br>
              </p>
              <p class="text-center">
                <strong>Reception:</strong><br>
                Saybrook Point Resort & Marina<br>
                2 Bridge St, Old Saybrook, CT 06475
              </p>
            </div>
            <p class="text-center mb-4">
            <router-link to="/" class="btn btn-primary btn-block">Back Home</router-link>
          </p>
          </template>
          <template v-if="step == 1">
            <h3 class="text-center mb-5">Let's start with your last name</h3>
            <p>If you're responding for you and a guest (or your family), you'll be able to RSVP for your entire group.
            </p>
            <div>
              <b-form-input v-model="name"></b-form-input>
            </div>
            <div class="button__group mt-5">
              <div class="loading" v-if="loading"></div>
              <button class="btn btn-primary btn-block" v-else :disabled="name ? false : 'disabled'" @click="getData">Find your
                invitation</button>
            </div>
          </template>
          <template v-if="step == 2">
            <div>
              <h3 class="text-center mb-5" v-if="!results.length">Oops! We’re having trouble finding your invite. Please
                check the spelling of your last name or contact the couple</h3>
              <h3 class="text-center mb-5" v-if="results.length == 1">We’ve found you on our guest list. Please confirm
                your name below to continue with your RSVP</h3>
              <h3 class="text-center mb-5" v-if="results.length > 1">We've found more than one match on our guest list.
                Please select your name from the list below.</h3>
              <template v-if="results.length > 0">
                <div class="name__selection text-center" v-if="name">
                  <b-form-group>
                    <b-form-radio-group v-model="selected" :options="results.map(item => item.name)" buttons stacked
                      style="gap: 1rem"></b-form-radio-group>
                  </b-form-group>
                </div>
              </template>
              <div class="button__group mt-5">
                <button class="btn btn-secondary btn-block" @click="prevStep">Back</button>
                <button class="btn btn-primary btn-block" :disabled="selected.length > 0 ? false : true"
                  @click="filterList">Next</button>
              </div>
            </div>
          </template>
          <template v-if="step == 3">
            <template v-if="selectedResults">
              <h3 class="text-center mb-5">Are you able to attend?</h3>
              <div class="attending__wrapper" v-for="item, i in selectedResults.guests" :key="i">
                <div class="form__item">
                  <div class="name__wrapper">
                    <h4>{{ item.name }}</h4>
                  </div>
                  <b-form-radio-group id="" v-model="selectedResults.guests[i].attending" :options="attendance_options"
                    name="" buttons></b-form-radio-group>
                </div>
                <b-form-group
                  v-if="selectedResults.guests[i].name == 'Guest' && selectedResults.guests[i].attending == 'Yes'"
                  label="What is your guest's name?">
                  <b-form-input id="" v-model="selectedResults.guests[i].guest_name" required></b-form-input>
                </b-form-group>
              </div>
              <div class="button__group mt-5">
                <button class="btn btn-secondary btn-block" @click="prevStep">Back</button>
                <button class="btn btn-primary btn-block" @click="checkAttending(selectedResults)">Next</button>
              </div>
            </template>
            <!-- <template v-else>
              <h3 class="text-center mb-5">You've already responded!</h3>
              <div class="attending__wrapper text-center mb-4" v-for="item, i in selectedResults.guests" :key="i">
                <h4>{{ item.name == 'Guest' && item.guest_name ? item.guest_name : item.name }}</h4>
                <div>
                  Attending: {{ item.attending }}
                  <div v-if="item.attending == 'Yes' && item.meal">Meal: {{ item.meal }}</div>
                  <div v-if="item.attending == 'Yes' && item.allergy">Food Allergy: {{ item.allergy }}</div>
                </div>
              </div>
              <div v-if="selectedResults.song" class="text-center mb-4">Song request: {{ selectedResults.song }}</div>
              <div v-if="selectedResults.song" class="text-center mb-4">Note: {{ selectedResults.note }}</div>
              <p class="text-center mb-5">If you have any questions please email <a
                  href="mailto:fyi@thefitzgeraldwedding.com">fyi@thefitzgeraldwedding.com</a>.</p>
              <p class="text-center">
                <router-link to="/" class="btn btn-primary btn-block">Back Home</router-link>
              </p>
            </template> -->
          </template>
          <template v-if="step == 4">
            <h3 class="text-center mb-3">Please select your main course</h3>
            <p class="text-center mb-4">Choice of:</p>
            <div class="text-center mb-5">
              <h5>Carved Filet Mignon with Forest Mushrooms</h5><p>Mashed potatoes, truffle-scented cabernet jus</p>
              <h5>Roast Breast of Chicken</h5><p>Pea risotto and natural chicken jus</p>
              <h5>Potato-crusted New England Cod Steak</h5><p>Lemon herb risotto</p>
              <h5>Chef's Seasonal Risotto (Vegetarian option)</h5><p>Risotto prepared, season vegetables</p>
            </div>
            <div class="attending__wrapper" v-for="item, i in selectedResults.guests" :key="i">
              <div class="form__item" v-if="item.attending == 'Yes'">
                <div class="name__wrapper">
                  <h4>{{ item.name == 'Guest' && item.guest_name ? item.guest_name : item.name }}</h4>
                </div>
                <b-form-select id="" v-model="selectedResults.guests[i].meal" :options="food_options"
                  name=""></b-form-select>
              </div>
              <b-form-group v-if="item.meal && item.attending == 'Yes'"
                label="Do you have any allergies or dietary restrictions?">
                <b-form-input id="" v-model="selectedResults.guests[i].allergy"></b-form-input>
              </b-form-group>
            </div>
            <div class="button__group mt-5">
              <button class="btn btn-secondary btn-block" @click="prevStep">Back</button>
              <button class="btn btn-primary btn-block" @click="nextStep">Next</button>
            </div>
          </template>
          <template v-if="step == 5">
            <h3 class="text-center mb-5">I promise to dance if this song comes on!</h3>
            <b-form-group>
              <b-form-input id="" v-model="selectedResults.song" name="" buttons></b-form-input>
            </b-form-group>
            <div class="button__group mt-5">
              <button class="btn btn-secondary btn-block" @click="prevStep">Back</button>
              <button class="btn btn-primary btn-block" @click="nextStep">Next</button>
            </div>
          </template>
          <template v-if="step == 6">
            <h3 class="text-center mb-5">Review</h3>
            <div class="attending__wrapper text-center mb-4" v-for="item, i in selectedResults.guests" :key="i">
              <h4>{{ item.name == 'Guest' && item.guest_name ? item.guest_name : item.name }}</h4>
              <div>
                Attending: {{ item.attending }}
                <div v-if="item.attending == 'Yes' && item.meal">Meal: {{ item.meal }}</div>
                <div v-if="item.attending == 'Yes' && item.allergy">Food Allergy: {{ item.allergy }}</div>
              </div>
            </div>
            <div v-if="selectedResults.song" class="text-center">Song request: {{ selectedResults.song }}</div>
            <div class="mt-5">
              <b-form-group label="Leave a note for the bride and groom (optional)">
                <b-textarea id="" v-model="selectedResults.note" name=""></b-textarea>
              </b-form-group>
            </div>
            <div class="button__group mt-5">
              <button class="btn btn-secondary btn-block" @click="prevStep" v-if="!notAttending">Back</button>
              <button class="btn btn-primary btn-block" @click="submitForm">Submit</button>
            </div>
          </template>
          <template v-if="step == 7">
            <h3 class="text-center mb-3">Thank you for responding!</h3>
            <h4 class="text-center mb-4">The Stevens-FitzGerald Wedding</h4>
            <div class="mb-5">
              <p class="text-center">
                September 16, 2023 | 1:00PM - 10:00PM</p>
              <p class="text-center">
                <strong>Ceremony:</strong><br>
                St. John's Roman Catholic Church<br>
                161 Main St, Old Saybrook, CT 06475<br>
              </p>
              <p class="text-center">
                <strong>Reception:</strong><br>
                Saybrook Point Resort & Marina<br>
                2 Bridge St, Old Saybrook, CT 06475
              </p>
            </div>
            <p class="text-center mb-4">
              <router-link to="/" class="btn btn-primary btn-block">Back Home</router-link>
            </p>
            <p class="text-center mb-5">If you have any questions please email <a
                href="mailto:fyi@thefitzgeraldwedding.com">fyi@thefitzgeraldwedding.com</a>.</p>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PageHeader from "../components/PageHeader.vue";
import { doc, setDoc, query, where, collection, getDocs } from "firebase/firestore";
import { db } from "../firebase"
import guests from "../assets/json/guests.json"
export default {
  components: {
    PageHeader,
  },
  data() {
    return {
      attendance_options: [{ text: "Yes", value: "Yes" }, { text: "No", value: "No" }],
      allergy_options: ["Yes", "No"],
      food_options: ["Carved Filet Mignon with Forest Mushrooms", "Roast Breast of Chicken", "Potato-crusted New England Cod Steak", "Chef's Seasonal Risotto"],
      step: 0,
      form: [],
      name: "",
      selected: "",
      results: [],
      selectedResults: {},
      loading: false,
      guests: guests,
      notAttending: false
    };
  },
  mounted() {
    //this.setData()
  },
  methods: {
    async setData() {
      const arr = this.guests
      for (const a of arr) {
        setDoc(doc(db, "wedding", a.id), a);
      }
    },
    async getData() {
      let arr = []
      this.loading = true
      const q = query(collection(db, "wedding"), where("last_names", "array-contains", this.name.toLowerCase()));

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        arr.push(doc.data());
      });
      this.results = arr
      this.loading = false
      this.nextStep()
    },
    nextStep() {
      this.step += 1
    },
    prevStep() {
      this.step -= 1
    },
    filterList() {
      var arr = this.results.filter(item => item.name == this.selected)[0]
      this.selectedResults = arr
      this.nextStep()
    },
    checkAttending(res) {
      var arr = res.guests.filter(item => item.attending == 'Yes')
      if (arr.length > 0) {
        this.nextStep()
      }
      else {
        this.notAttending = true
        this.step = 6
      }
    },
    submitForm() {
      var o = this.selectedResults
      o.saved = new Date().toLocaleString("en-US")
      setDoc(doc(db, "wedding", o.id), o);
      this.step += 1
    }
  },
  computed: {
    // filteredNames() {
    //   return this.results.filter(w => {
    //     return w.name.toUpperCase().includes(this.name.toUpperCase());
    //   });
    // }
  }
};
</script>
<style lang="scss" scoped>
h3 {
  font-weight: 400;
}

.rsvp__container {
  max-width: 600px;
  margin: 0 auto;
}

.form__item {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-bottom: 1rem;
  justify-content: space-between;
}

.form__item>* {
  flex: 1;
}

.name__wrapper h4 {
  margin: 0;
}

.attending__wrapper span {
  flex: 1;
}

.button__group {
  display: flex;
  gap: 1rem;
}

.button__active {
  background-color: #ecf2f7;
}

.button__group .btn-block+.btn-block {
  margin: 0;
}
</style>