<template>
  <div class="registry-page">
    <PageHeader header="Our Registry" />
    <div class="container">
      <div class="text-center slim">
        <!-- <h2>Lorem Ipsum</h2>
      <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula
eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient
montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque</p> -->
        <a href="https://www.zola.com/registry/fitzgerald2023" class="text-link" target="_blank">View on Zola</a>
      </div>
      <div v-if="loading" class="loading"></div>
      <div v-else class="registry__wrapper">
      <div class="filters">
        <b-form-group label="Sort By">
          <b-select type="text" v-model="sortBy" :options="sort_options"></b-select>
        </b-form-group>
        <div v-if="this.sortBy != 'Default'" @click="resetFilter" class="reset">
          Reset
        </div>
      </div>
      <div class="registry">
        <div class="product-grid">
          <div class="product" v-for="item in filteredList" :key="item.object_id"
            :class="{ 'out-of-stock': item.stock_message == 'Out of stock.', 'purchased': item.contributions.show_as_fulfilled, 'discontinued':item.discontinued }">
            <div class="product-image-wrapper">
              <a :href="`https://www.zola.com/registry/collection-item/${item.object_id}`" target="_blank"
                @click="viewItem(item)">
                <img class="product-image img-fluid" :src="item.images[0].large" />
              </a>
              <div class="sale-badge" v-if="item.price < item.msrp">SALE</div>
            </div>
            <div class="product-info">
              <div class="product-info-top">
                <h6 v-if="item.brand" class="product-brand">
                  {{ item.brand.name }}
                </h6>
                <h6 v-else class="product-brand">{{ item.store_name }}</h6>
                <h4 class="product-name">
                  <a :href="`https://www.zola.com/registry/collection-item/${item.object_id}`" target="_blank"
                    @click="viewItem(item)">{{
                        item.name
                    }}</a>
                </h4>
                <div class="price-block" v-if="!item.cash_fund">
                  <div class="product-price" v-if="!item.contributions.show_as_fulfilled">
                    <span style="opacity: 0.5" v-if="item.price < item.msrp"><s>${{ item.msrp.toFixed(2)
                    }}</s>&nbsp;&nbsp;</span><span>${{ item.price.toFixed(2) }}</span>
                  </div>
                  <div v-else class="purchased">Purchased</div>
                </div>
              </div>
              <div class="product-info-bottom">
                <a v-if="!item.contributions.show_as_fulfilled" @click="viewItem(item)"
                  :href="`https://www.zola.com/registry/collection-item/${item.object_id}`" target="_blank"
                  class="btn btn-primary">
                  <span v-if="item.cash_fund">Contribute</span><span v-else>View Product</span></a>
                <p class="small mt-3 product-requested" v-if="
                  !item.contributions.show_as_fulfilled && !item.cash_fund
                ">
                  Would Like: <strong>{{ item.requested_quantity }}</strong> |
                  Still Needs:
                  <strong>{{ item.contributions.still_needs }}</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>
<script>
import PageHeader from "../components/PageHeader.vue";
import metaInfo from '../mixins/metaInfo'
import { getAnalytics, logEvent } from "firebase/analytics";

export default {
  components: {
    PageHeader,
  },
  mixins: [
    metaInfo
  ],
  data() {
    return {
      description: 'Shop Samantha Stevens and Matthew FitzGerald\'s  Wedding Registry',
      registry: [],
      registrySort: [],
      loading: true,
      sortBy: "Default",
      sort_options: ["Default", "Price: Low to High", "Price: High to Low"],
    };
  },
  mounted() {
    this.getRegistry();
  },
  methods: {
    getRegistry() {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(
        "https://script.google.com/macros/s/AKfycbyQRu6mZA8wKuI5IesDbk6ecDt2uyISwD-M3jBGSUhIpORnioL-73JGpqOHmNx2D80g/exec",
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          var res = JSON.parse(result);
          var collection = res.default_collection;
          this.registry = collection;
          console.log(collection);
          this.loading = false;
        })

        .catch((error) => console.log("error", error));
    },
    resetFilter() {
      this.sortBy = "Default";
    },
    viewItem(item) {
      const analytics = getAnalytics();
      logEvent(analytics, 'view_item', {
        currency: "USD",
        value: item.price,
        items: [
          {
            item_id: item.object_id,
            item_name: item.name,
            price: item.price,
          }
        ]
      });
    }
  },
  computed: {
    filteredList() {
      var sortBy = this.sortBy;
      var registry = [...this.registry];
      if (sortBy == "Default") {
        return this.registry;
      } else if (sortBy == "Price: Low to High") {
        return registry.sort((prev, curr) => prev.price - curr.price);
      } else if (sortBy == "Price: High to Low") {
        return registry.sort((prev, curr) => curr.price - prev.price);
      } else {
        return this.registry;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.filters {
  display: flex;
  justify-content: flex-start;
  margin-top: 2em;
  gap: 15px;
  align-items: center;
}

.reset {
  cursor: pointer;
  margin-top: 15px;
  color: #495057;
}

.registry {
  margin: 1em 0 3em 0;
}

.product-grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 30px;
}

.product {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.product {
  &.out-of-stock {
    .product-image-wrapper::after {
      content: "Out of Stock";
      color: #fff;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #00000075;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
    }
  }
  &.discontinued {
    .product-image-wrapper::after {
      content: "Discontinued";
      color: #fff;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #00000075;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
    }
    .product-info-bottom {
      display: none;
    }
  }
  &.purchased {
    .product-image {
      opacity: .25;
    }
  }
}

.product-name {
  font-size: 18px;
  line-height: 1.4;
}

.product-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
}

.product-info-top {
  margin: 1em 0;
}

.product-image-wrapper {
  position: relative;
}

.sale-badge {
  position: absolute;
  top: 10px;
  left: 10px;
  background: rgb(255, 56, 56);
  border-radius: 50px;
  font-size: 11px;
  color: #fff;
  padding: 5px 10px;
  font-weight: 600;
  line-height: 1;
}

.product-image:hover {
  opacity: 0.8;
  transition: all 0.3s ease-in-out;
}

.product-image {
  opacity: 1;
  transition: all 0.3s ease-in-out;
}

.purchased {
  margin-top: 1em;
}

@media screen and (max-width: 767px) {
  .product-grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 15px;
  }

  .product-name {
    font-size: 16px;
  }

  .small {
    font-size: 0.75em;
  }
}
</style>