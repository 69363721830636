<template>
  <div class="schedule-page">
    <PageHeader header="Our Wedding Day"/>
    <div class="container">
      <div class="text-center slim">
      <!-- <h2>Timeline of our wedding events</h2>
      <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula
eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient
montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque</p> -->
      </div>
      <div class="schedule">
        <div class="schedule-item" v-for="item in schedule" :key="item.title">
          <div class="schedule-name">
          <h3 v-if="item.title">{{item.title}}</h3>
          <p>
            <span class="date" v-if="item.date">{{item.date}}</span>
            <span class="time" v-if="item.time">{{item.time}}</span>
            </p>
          </div>
          <div class="schedule-details">
            <div class="schedule-icon" v-if="item.icon">
              <img :src="require(`@/assets/images/${item.icon}.png`)" />
            </div>
            <p>
          <span class="location" v-if="item.location">{{item.location}}<br>{{item.address}}</span>
          <span class="attire" v-if="item.attire">{{item.attire}}</span></p>
          <a class="directions text-link" :href="item.directions" target="_blanks">Get directions</a>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>
<script>
import PageHeader from '../components/PageHeader.vue'
import metaInfo from '../mixins/metaInfo'

export default {
  components: {
    PageHeader,
  },
    mixins: [
    metaInfo
  ],
  data() {
    return {
      description: 'Explore Samantha Stevens and Matthew FitzGerald\'s Wedding Day Schedule',
      schedule: [
        /*{
        title: "Rehearsal Dinner",
        date: "Friday, September 15th, 2023",
        icon: "heart",
        time: "6:00PM - 10:00PM",
        location: "TBD",
        address: "TBD"
        },*/
        {
        title: "Ceremony",
        date: "Saturday, September 16th, 2023",
        icon: "rings",
        time: "1:00PM - 2:00PM",
        location: "St. Johns Church",
        address: "161 Main St, Old Saybrook, CT 06475",
        attire: "Formal attire",
        directions: "https://goo.gl/maps/qer6J4PXvmJTq9SA9"
        },
        /*{
        title: "",
        description: "During this time we encourage our guests to check-in to any acoomodations made for the night. There are also 2 bars open at the Saybrook Point to enjoy while we are taking family photos.",
        time: "",
        location: ""
        },*/
        {
        title: "Cocktail Hour & Reception",
        date: "Saturday, September 16th, 2023",
        icon: "cake",
        time: "5:00PM - 10:00PM",
        location: "Saybrook Point Resort & Marina",
        address: "2 Bridge St, Old Saybrook, CT 06475",
        attire: "Formal attire",
        directions: "https://goo.gl/maps/ujAsxnsdRcoMLyPTA"
        },
        {
        title: "After Party",
        date: "Saturday, September 16th, 2023",
        icon: "drinks",
        time: "10:00PM",
        location: "Fresh Salt Bar @ Saybrook Point Resort & Marina",
        address: "2 Bridge St, Old Saybrook, CT 06475",
        attire: "",
        directions: "https://goo.gl/maps/ujAsxnsdRcoMLyPTA"
        },
      ]
    }
  },
}
</script>
<style scoped>
.schedule {
  max-width: 1000px;
  margin: 3em auto;
  text-align: center;
}
.schedule-day {
  margin-bottom: 3em;
}
.schedule-item {
  margin-bottom: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items:center;
  position: relative;
}
.schedule-details {
  padding: 3em 4em;
  position:relative;

}
.schedule-icon img {
  max-width: 50px;
  margin: 0 auto 1em auto;
}
.schedule-name {
  padding: 3em 4em;
  padding: relative;
}
.schedule-name::after {
  content:"";
  position:absolute;
  top:0;
  left:50%; /* Half the gap */
  height:100%; /* Big height*/
  width:1px;
  background:#d8d8d8;
  }
.schedule-item span {
  display: block;
  margin-bottom: 1em;
}
.time {
  color: #888
}
.location {
}
.description {
  font-style: italic;
}
.date {
  margin-top: 1em;
  margin-bottom: .5em!important;
}
h3 {
  text-transform: uppercase;
  letter-spacing: 3px;
  margin-top: 1em;
}
@media screen and (max-width:767px) {
  .schedule-item {
  margin-bottom: 5em;
  grid-template-columns: repeat(1, 1fr);
}
.schedule-details {
  padding: 0;
}
.schedule-name {
  padding: 0;
}
.schedule-name::after {
display: none;
  }
}
</style>