<template>
  <div class="registry-page">
    <PageHeader header="Rehearsal Dinner" />
    <div class="container rsvp__container">
      <div class="row">
        <div class="col-sm-12">
          <template v-if="step == 1">
            <h3 class="text-center mb-5">Let's start with your last name</h3>
            <p class="text-center">If you're responding for you and a guest, you'll be able to RSVP for both.
            </p>
            <div>
              <b-form-input v-model="name"></b-form-input>
            </div>
            <div class="button__group mt-5">
              <div class="loading" v-if="loading"></div>
              <button class="btn btn-primary btn-block" v-else :disabled="name ? false : 'disabled'" @click="getData">Find
                your
                invitation</button>
            </div>
          </template>
          <template v-if="step == 2">
            <div>
              <h3 class="text-center mb-5" v-if="!results.length">Oops! We’re having trouble finding your invite. Please
                check the spelling of your last name or contact the couple</h3>
              <h3 class="text-center mb-5" v-if="results.length == 1">We’ve found you on our list. Please confirm
                your name below to continue with your RSVP</h3>
              <h3 class="text-center mb-5" v-if="results.length > 1">We've found more than one match on our list.
                Please select your name from the list below.</h3>
              <template v-if="results.length > 0">
                <div class="name__selection text-center" v-if="name">
                  <b-form-group>
                    <b-form-radio-group v-model="selected" :options="results.map(item => item.name)" buttons stacked
                      style="gap: 1rem"></b-form-radio-group>
                  </b-form-group>
                </div>
              </template>
              <div class="button__group mt-5">
                <button class="btn btn-secondary btn-block" @click="prevStep">Back</button>
                <button class="btn btn-primary btn-block" :disabled="selected.length > 0 ? false : true"
                  @click="filterList">Next</button>
              </div>
            </div>
          </template>
          <template v-if="step == 3">
            <template v-if="selectedResults">
              <h3 class="text-center mb-5">Are you able to attend?</h3>
              <div class="attending__wrapper" v-for="item, i in selectedResults.guests" :key="i">
                <div class="form__item">
                  <div class="name__wrapper">
                    <h4>{{ item.name }}</h4>
                  </div>
                  <b-form-radio-group id="" v-model="selectedResults.guests[i].attending" :options="attendance_options"
                    name="" buttons></b-form-radio-group>
                </div>
                <div v-if="item.attending == 'Yes'" class="mb-5">
                  <b-form-group label="Please select your meal choice">
                    <b-form-select id="" v-model="selectedResults.guests[i].meal" :options="food_options"
                      name=""></b-form-select></b-form-group>
                </div>
              </div>
              <div class="button__group mt-5">
                <button class="btn btn-secondary btn-block" @click="prevStep">Back</button>
                <button class="btn btn-primary btn-block" @click="submitForm"
                  :disabled="selectedResults.guests[0].attending ? false : 'disabled'">Submit RSVP</button>
              </div>
            </template>
          </template>
          <template v-if="step == 4">
            <h3 class="text-center mb-3">Thank you for responding!</h3>
            <h4 class="text-center mb-4">Samantha & Matthew's Rehearsal Dinner</h4>
            <div class="mb-5">
              <p class="text-center">
                September 15, 2023 | 6:00PM</p>
              <p class="text-center">
                Cafe Allegre<br>
                725 Boston Post Road<br>Madison, CT 06443
              </p>
            </div>
            <p class="text-center mb-4">
              <router-link to="/" class="btn btn-primary btn-block">Back Home</router-link>
            </p>
            <p class="text-center mb-5">If you have any questions please email <a
                href="mailto:fyi@thefitzgeraldwedding.com">fyi@thefitzgeraldwedding.com</a>.</p>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PageHeader from "../components/PageHeader.vue";
import { doc, setDoc, query, where, collection, getDocs } from "firebase/firestore";
import { db } from "../firebase"
import guests from "../assets/json/rehearsal.json"
export default {
  components: {
    PageHeader,
  },
  data() {
    return {
      attendance_options: [{ text: "Yes", value: "Yes" }, { text: "No", value: "No" }],
      allergy_options: ["Yes", "No"],
      food_options: ["New York Strip Steak", "Chicken Francaise", "Salmon Champagne", "Swordfish Mediterraneo", "Pasta Primavera (Vegetarian)"],
      step: 1,
      form: [],
      name: "",
      selected: "",
      results: [],
      selectedResults: {},
      loading: false,
      guests: guests,
      notAttending: false
    };
  },
  mounted() {
    //this.setData()
  },
  methods: {
    async setData() {
      const arr = this.guests
      for (const a of arr) {
        setDoc(doc(db, "rehearsal", a.id), a);
      }
    },
    async getData() {
      let arr = []
      this.loading = true
      const q = query(collection(db, "rehearsal"), where("last_names", "array-contains", this.name.toLowerCase()));

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        arr.push(doc.data());
      });
      this.results = arr
      this.loading = false
      this.nextStep()
    },
    nextStep() {
      this.step += 1
    },
    prevStep() {
      this.step -= 1
    },
    filterList() {
      var arr = this.results.filter(item => item.name == this.selected)[0]
      this.selectedResults = arr
      this.nextStep()
    },
    submitForm() {
      var o = this.selectedResults
      o.saved = new Date().toLocaleString("en-US")
      setDoc(doc(db, "rehearsal", o.id), o);
      this.step += 1
    }
  },
  computed: {
  }
};
</script>
<style lang="scss" scoped>
h3 {
  font-weight: 400;
}

.rsvp__container {
  max-width: 600px;
  margin: 0 auto;
}

.form__item {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-bottom: 1rem;
  justify-content: space-between;
}

.form__item>* {
  flex: 1;
}

.name__wrapper h4 {
  margin: 0;
}

.attending__wrapper span {
  flex: 1;
}

.button__group {
  display: flex;
  gap: 1rem;
}

.button__active {
  background-color: #ecf2f7;
}

.button__group .btn-block+.btn-block {
  margin: 0;
}
</style>