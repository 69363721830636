<template>
      <div class="page-header">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 text-center">
        <h1>{{header}}</h1>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
export default {
props: {
  header: String
}
}
</script>
<style scoped>
.page-header {
  background-color: #ecf2f7;
  padding: 5em 0;
  margin-bottom: 4em;
}
h1 {
  font-size: 5em!important;
  margin-bottom: 0;
  color: #2a3c4dbd;
}
@media screen and (max-width: 767px){
  h1 {
  font-size: 4em!important;
  margin-bottom: 0;
}
}

</style>