<template>
  <div id="app">
    <Header />
    <div class="page-content">
      <router-view />
    </div>
    <Footer />
  </div>
</template>


<script>

import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
export default {
  components: {
    Header,
    Footer
  }
}
</script>
<style>
.page-content {
  min-height: 100vh;
}
</style>
