<template>
    <div class="registry-page">
        <PageHeader header="Bridal Shower" />
        <div class="loading" v-if="loading"></div>
        <div class="container" v-else>
            <div class="row">
                <div class="col-sm-12 results__wrapper">
                    <p>Attending: {{ attending.yes }} | Not Attending: {{ attending.no }} | Unanswered: {{ attending.unanswered }}</p>
                    <table class="w-100 table table-bordered">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Attending</th>
                                <th>Allergies</th>
                                <th>Note</th>
                                <th>Submitted</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in results" :key="item.id">
                                <td>{{ item.name }}</td>
                                <td>{{ item.attending }}</td>
                                <td>{{ item.allergy }}</td>
                                <td>{{ item.note }}</td>
                                <td>{{ item.saved }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import PageHeader from "../components/PageHeader.vue";
import { getDocs, collection } from "firebase/firestore";
import { db } from "../firebase"
export default {
    components: {
        PageHeader,
    },
    data() {
        return {
            results: [],
            loading: true
        };
    },
    mounted() {
        this.getData()
    },
    methods: {
        async getData() {
            let arr = []
            const querySnapshot = await getDocs(collection(db, 'shower'));
            querySnapshot.forEach((doc) => {
                arr.push(doc.data());
            });
            this.results = arr
            this.loading = false
        }
    },
    computed: {
        sortedGuests() {
            return this.results.slice().sort((a, b) => {
                const aLastName = a.name.split(' ').pop();
                const bLastName = b.name.split(' ').pop();
                return aLastName.localeCompare(bLastName);
            });
        },
        attending() {
            let attending = {
                yes: 0,
                no: 0,
                unanswered: 0
            }
            for (const a of this.results) {
                switch (a.attending) {
                    case "Yes":
                        attending.yes += 1
                        break;
                    case "No":
                        attending.no += 1
                        break;
                    default:
                        attending.unanswered += 1
                        break;
                }
            }
            return attending

        }
    }
}
</script>
<style scoped>
.results__wrapper {
    overflow-x: auto;
}
</style>