<template>
    <div class="registry-page">
        <PageHeader header="Rehearsal Dinner Results" />
        <div class="loading" v-if="loading"></div>
        <div class="container-fluid" v-else>
            <div class="row">
                <div class="col-sm-12">
                    <p>Attending: {{ attending.yes }} | Not Attending: {{ attending.no }} | Unanswered: {{
                        attending.unanswered }}</p>
                    <p>Steak: {{ meals.steak }} | Chicken: {{ meals.chicken }} | Salmon: {{ meals.salmon }} |  Swordfish: {{ meals.swordfish }} | Veggie: {{
                        meals.veggie }}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 results__wrapper">
                    <table class="w-100 table table-bordered">
                        <thead>
                            <tr>
                                <th>Invite</th>
                                <th>Guest</th>
                                <th>Responded</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in sortedInvites" :key="item.id">
                                <td>{{ item.name }}</td>
                                <td>
                                    <div v-if="item.saved">
                                        <div v-for="guest in item.guests" :key="guest.index" class="mb-3">
                                            <div>{{ !guest.guest_name ? guest.name : guest.guest_name
                                            }}</div>
                                            <div><strong>Attending:</strong> <span :class="`attending--${guest.attending ? guest.attending.toLowerCase() : ''}`">{{ guest.attending }}</span></div>
                                            <div v-if="guest.attending == 'Yes'">
                                                <div><strong>Meal:</strong> {{ guest.meal }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>{{ item.saved }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import PageHeader from "../components/PageHeader.vue";
import { getDocs, collection } from "firebase/firestore";
import { db } from "../firebase"
export default {
    components: {
        PageHeader,
    },
    data() {
        return {
            results: [],
            invites: [],
            loading: true
        };
    },
    mounted() {
        this.gedivata()
    },
    methods: {
        async gedivata() {
            let arr = []
            let inv = []
            const querySnapshot = await getDocs(collection(db, 'rehearsal'));
            querySnapshot.forEach((doc) => {
                inv.push(doc.data())
                doc.data().guests.forEach((a) => {
                    arr.push(a)
                })
            });
            this.invites = inv
            this.results = arr
            this.loading = false
        }
    },
    computed: {
        sortedInvites() {
        let arr = this.invites;
        arr.sort((a, b) => {
            const dateA = a.saved ? new Date(a.saved) : new Date(0); // Use the earliest possible date for blank values
            const dateB = b.saved ? new Date(b.saved) : new Date(0);
            return dateB - dateA;
        });
        return arr;
    },
        attending() {
            let attending = {
                yes: 0,
                no: 0,
                unanswered: 0
            }
            for (const a of this.results) {
                switch (a.attending) {
                    case "Yes":
                        attending.yes += 1
                        break;
                    case "No":
                        attending.no += 1
                        break;
                    default:
                        attending.unanswered += 1
                        break;
                }
            }
            return attending
        },
        meals() {
            let meals = {
                steak: 0,
                chicken: 0,
                salmon: 0,
                swordfish: 0,
                veggie: 0
            }
            for (const a of this.results) {
                switch (a.meal) {
                    case "New York Strip Steak":
                        meals.steak += 1
                        break;
                    case "Chicken Francaise":
                        meals.chicken += 1
                        break;
                    case "Salmon Champagne":
                        meals.salmon += 1
                        break;
                    case "Swordfish Mediterraneo":
                        meals.swordfish += 1
                        break;
                    case "Pasta Primavera (Vegetarian)":
                        meals.veggie += 1
                }
            }
            return meals
        }
    }
}
</script>
<style lang="scss" scoped>
.results__wrapper {
    overflow-x: auto;
}

td {
    width: 200px !important;
}
th {
    min-width: 250px;
}
.attending {
    &--yes {
        color: green;
    }
    &--no {
        color: red;
    }
}
</style>