<template>
  <div class="home">
    <Hero />
    <div class="section info-section">
      <div class="container text-center">
        <div class="row">
          <div class="col-sm-12">
            <img src="~@/assets/images/weddingrings.png" class="img-fluid"
              style="width: 50px; margin: 0 auto 1em auto; opacity: 0.5" />
            <h3 class="uppercase">Saturday, September 16, 2023</h3>
            <p class="uppercase mt-3">Old Saybrook, Connecticut</p>
            <!-- <router-link to="/schedule" class="text-link">Our Wedding Day</router-link> -->
          </div>
        </div>
        <div class="row slim">
          <div class="col-sm-12">
            <div class="links-grid">
              <div class="link" v-for="item in links" :key="item.name">
                <router-link :to="item.path" :href="item.href" :target="item.href ? '_blank' : ''"
                  class="btn btn-secondary btn-block">{{ item.name }}
                  <!-- <div>
                    <img :src="require(`../assets/images/${item.icon}`)" class="img-fluid icon" />
                    <h4>{{ item.name }}</h4>
                  </div> -->
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-4 mt-3" v-for="image in images" :key="image">
            <img :src="require(`../assets/images/${image}`)" class="img-fluid" />
          </div>
        </div>
      </div>
    </div>
    <div class="section wedding-party__section">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <h2 class="section-title text-center">Our Wedding Party</h2>
            <div class="wedding-party">
              <div class="member" v-for="member in weddingParty" :key="member.name">
                <div class="member-image">
                  <img v-if="member.img" :src="require(`../assets/images/${member.img}`)" class="img-fluid" />
                </div>
                <div class="member-name">
                  <h4>{{ member.name }}</h4>
                </div>
                <div class="member-role">
                  <p>{{ member.role }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Hero from "@/components/Hero.vue";
import metaInfo from '../mixins/metaInfo'

export default {
  name: "Home",
  components: {
    Hero,
  },
  mixins: [
    metaInfo
  ],
  data() {
    return {
      description: 'The Wedding of Ms. Samantha Stevens and Mr. Matthew FitzGerald',
      links: [
        {
          name: "RSVP",
          path: "/rsvp/wedding",
          icon: "heart.png",
        },
        {
          name: "Wedding Day",
          path: "/schedule",
          icon: "rings.png",
        },
        {
          name: "Where to Stay",
          path: "/accommodations",
          icon: "stay.png",
        },
        {
          name: "Registry",
          path: "/registry",
          icon: "gifts.png",
        }
      ],
      images: [
        "Sam  Matt E-Session-19.jpg",
        "Sam  Matt E-Session-92.jpg",
        "Sam  Matt E-Session-103.jpg",
      ],
      weddingParty: [
        {
          name: "Stephanie Carroll",
          role: "Maid of Honor",
          img: "steph.jpg",
        },
        {
          name: "Sabrina Hackett",
          role: "Bridesmaid",
          img: "sabrina.jpg",
        },
        {
          name: "Jackie Petracca",
          role: "Bridesmaid",
          img: "jackie.jpg",
        },

        {
          name: "Laura Levis",
          role: "Bridesmaid",
          img: "laura.jpg",
        },
        {
          name: "Sarah Coady",
          role: "Bridesmaid",
          img: "sarah.jpg",
        },
        {
          name: "John FitzGerald",
          role: "Best Man",
          img: "johno.jpg",
        },
        {
          name: "Jason Hackett",
          role: "Groomsman",
          img: "jason.jpg",
        },
        {
          name: "Christopher Serra",
          role: "Groomsman",
          img: "chris.jpg",
        },
        {
          name: "James FitzGerald",
          role: "Groomsman",
          img: "james.jpg",
        },
        {
          name: "Michael FitzGerald",
          role: "Groomsman",
          img: "michael.jpg",
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
h2 {
  text-align: center;
  font-size: 4rem;
  font-family: "Serenity", cursive;
  font-weight: 300;
  font-style: normal;
}

.info-section h3 {
  font-size: 2rem;
}

.wedding-party__section {
  padding: 3em 0;
}

.wedding-party {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin-top: 3em;
  gap: 1rem;
  text-align: center;
}

.wedding-party .member {
  margin-bottom: 1em;
}

.wedding-party .member-name h4 {
  margin-top: 1em;
}

.wedding-party .member-role {
  text-transform: uppercase;
  font-size: 0.8rem;
  letter-spacing: 1px;
}

.wedding-party .member-image {
  max-height: 150px;
  max-width: 150px;
  width: 100%;
  height: auto;
  margin: 0 auto;
  object-fit: cover;
}

.wedding-party .member-image img {
  border-radius: 100px;
  background-color: #d8d8d8;
}

.links-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  text-align: center;
  margin: 2em 0;
}

.links-grid .icon {
  max-width: 50px;
  margin-bottom: 1em;
  width: 100%;
  opacity: 0.5;
}

@media (max-width: 768px) {
  .hero-content h1 {
    font-size: 5rem !important;
    padding: 0 1em;
  }

  .links-grid {
    grid-template-columns: repeat(1, 1fr);
    gap: 15px;
  }

  .wedding-party {
    grid-template-columns: repeat(2, 1fr);

    .member:nth-child(1) {
      order: 1;
    }

    .member:nth-child(2) {
      order: 3;
    }

    .member:nth-child(3) {
      order: 5;
    }

    .member:nth-child(4) {
      order: 7;
    }

    .member:nth-child(5) {
      order: 9;
    }

    .member:nth-child(6) {
      order: 2;
    }

    .member:nth-child(7) {
      order: 4;
    }

    .member:nth-child(8) {
      order: 6;
    }

    .member:nth-child(9) {
      order: 8;
    }

    .member:nth-child(10) {
      order: 10;
    }
  }
}
</style>
