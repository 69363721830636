<template>
    <div class="rsvp-page">
        <PageHeader header="RSVP" />
        <div class="">
            <div class="container text-center">
                <div class="row">
                    <div class="col-sm-12">
                        <h3>Please select an event to RSVP to</h3>
                        <!-- <router-link to="/schedule" class="text-link">Our Wedding Day</router-link> -->
                    </div>
                </div>
                <div class="row slim mt-5">
                    <div class="col-sm-12">
                        <div class="card-grid">
                            <div class="rsvp__item" v-for="item in links" :key="item.name">
                                <router-link :to="item.path" :href="item.href" :target="item.href ? '_blank' : ''"
                                    :class="item.disabled ? 'disabled' : ''">
                                    <img :src="require(`../assets/images/${item.icon}`)" class="img-fluid icon" />
                                    <h4>{{ item.name }}</h4>
                                    <p>{{ item.datetime }}<br>{{ item.venue }}<br>{{ item.address }}</p>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
// @ is an alias to /src
import metaInfo from '../mixins/metaInfo'
import PageHeader from '@/components/PageHeader.vue';
export default {
    name: "RSVP",
    components: {
        PageHeader
    },
    mixins: [
        metaInfo
    ],
    data() {
        return {
            description: 'The Wedding of Ms. Samantha Stevens and Mr. Matthew FitzGerald',
            links: [
                {
                    name: "Wedding",
                    path: "/rsvp/wedding",
                    icon: "rings.png",
                    disabled: false,
                    datetime: "September 16, 2023 | 1:00PM - 10:00PM",
                    venue: "St. John's Church / Saybrook Point Resort",
                    address: "Old Saybrook, CT. 06475"
                }
            ]
        }
    }
};
</script>
<style lang="scss" scoped>
.card-grid {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 2rem;
}

.rsvp__item {
    width: 100%;
    border: 1px solid #ecf2f7;
    border-radius: 8px;

    a {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 2rem;
        font-weight: 300;

        img {
            max-width: 100px;
            margin: 0 auto;
            opacity: .5;
        }
    }
}

.disabled {
    opacity: .33;
}

@media screen and (max-width: 767px) {
    .card-grid {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}
</style>
  