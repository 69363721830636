import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Accommodations from '../views/Accommodations.vue'
import Schedule from '../views/Schedule.vue'
import Registry from '../views/Registry.vue'
import RSVP from '../views/RSVP.vue'
import ShowerRSVP from '../views/ShowerRSVP.vue'
import WeddingRSVP from '../views/WeddingRSVP.vue'
import ShowerResults from '../views/ShowerResults.vue'
import WeddingResults from '../views/WeddingResults.vue'
import WeddingResultsDetail from '../views/WeddingResultsDetail.vue'
import RehearsalRSVP from '../views/RehearsalRSVP.vue'
import RehearsalResults from '../views/RehearsalResults.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
        title: 'Home',
      }
},
{
    path: '/accommodations',
    name: 'Accommodations',
    component: Accommodations,
    meta: {
        title: 'Accommodations',
      }
},
{
    path: '/schedule',
    name: 'Schedule',
    component: Schedule,
    meta: {
        title: 'Schedule',
      }
},
{
    path: '/registry',
    name: 'Registry',
    component: Registry,
    meta: {
        title: 'Registry',
      }
},
{
    path: '/rsvp',
    name: 'RSVP',
    component: RSVP,
    meta: {
        title: 'RSVP',
      }
},
{
    path: '/rsvp/shower',
    name: 'Bridal Shower RSVP',
    component: ShowerRSVP,
    meta: {
        title: 'Bridal Shower RSVP',
      }
},
{
    path: '/rsvp/wedding',
    name: 'Wedding RSVP',
    component: WeddingRSVP,
    meta: {
        title: 'Wedding RSVP',
      }
},
{
    path: '/rsvp/shower/results',
    name: 'Bridal Shower RSVP Results',
    component: ShowerResults,
    meta: {
        title: 'Bridal Shower RSVP Results',
      }
},
{
    path: '/rsvp/wedding/results',
    name: 'Wedding RSVP Results',
    component: WeddingResults,
    meta: {
        title: 'Wedding RSVP Results',
      }
},
{
    path: '/rsvp/wedding/results-detail',
    name: 'Wedding RSVP Results Detail',
    component: WeddingResultsDetail,
    meta: {
        title: 'Wedding RSVP Results Detail',
      }
},
{
    path: '/rsvp/rehearsal-dinner',
    name: 'Rehearsal Dinner RSVP',
    component: RehearsalRSVP,
    meta: {
        title: 'Rehearsal Dinner RSVP',
      }
},
{
    path: '/rsvp/rehearsal-dinner/results',
    name: 'Rehearsal Dinner RSVP Results',
    component: RehearsalResults,
    meta: {
        title: 'Rehearsal Dinner RSVP Results',
      }
},
]

const router = new VueRouter({
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

import { getAnalytics, logEvent } from "firebase/analytics";
router.beforeEach((to, from, next) => {
        document.title = to.meta.title + " | The FitzGerald Wedding"
        const analytics = getAnalytics();
        logEvent(analytics, 'page_view', {
            page_title: document.title,
            traffic_type: window.location.host != "thefitzgeraldwedding.com" ? 'internal' : ''
        });
        console.log("Pageview " + window.location.host != "thefitzgeraldwedding.com" ? 'internal' : '')
        next()
})
export default router
