<template>
  <div class="accommodations-page">
    <PageHeader header="Where to Stay" />
    <div class="container">
      <!-- <div class="text-center slim">
      <h2>Stay the night</h2>
      <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula
eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient
montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque</p>
      </div> -->
      <div class="accommodations my-3">
        <div :class="`location ${item.booked}`" v-for="item in accommodations" :key="item.name">
          <div class="location-image">
            <img :src="require(`../assets/images/${item.image}`)" class="img-fluid" />
          </div>
          <div class="location-info">
            <h3><a :href="item.url" target="_blank">{{item.name}}</a></h3>
            <h5 class="mb-3">{{item.location}}</h5>
            <div v-if="item.phone"><a :href="`tel:+1-${item.phone}`">{{item.phone}}</a></div>
            <!-- <div v-if="item.price">{{item.price}}</div> -->
            <div class="mt-3">{{item.distance}} from venue</div>
            <div class="location-links mt-3">
              <a :href="item.url" class="text-link" target="_blank">View Hotel</a>
              <a :href="item.directions" class="text-link" target="_blank">Get Directions</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PageHeader from '../components/PageHeader.vue'
import metaInfo from '../mixins/metaInfo'
export default {
  components: {
    PageHeader,
  },
  mixins: [
    metaInfo
  ],
  data() {
    return {
      description: "View Accommodations for Samantha Stevens and Matthew FitzGerald's Wedding Day",
      accommodations: [
        {
          name: "Saybrook Point Resort & Marina (Reception Venue)",
          image: "saybrook-point.jpg",
          location: "Old Saybrook, CT",
          price: "$$$",
          url: "https://www.saybrook.com",
          phone: "860-395-2000",
          distance: "0 mi | 0 min",
          directions: "https://goo.gl/maps/ujAsxnsdRcoMLyPTA"
        },
        {
          name: "Waters Edge Resort & Spa",
          image: "waters-edge.jpg",
          location: "Westbrook, CT",
          price: "$$$",
          url: "https://watersedgeresortandspa.com/",
          phone: "860-399-5901",
          distance: "5 mi | 11 min",
          directions: "https://goo.gl/maps/NGbDJdRcVDzUM6Ur8"
        },
        {
          name: "Quality Inn Old Saybrook - Westbrook",
          image: "quality-inn.jpg",
          location: "Old Saybrook, CT",
          price: "$",
          url: "https://www.hotels.com/ho106319/quality-inn-old-saybrook-westbrook-old-saybrook-united-states-of-america/?chkin=2023-09-16&chkout=2023-09-17&expediaPropertyId=3988",
          phone: "860-395-1414",
          distance: "4.6 mi | 10 min",
          directions: "https://goo.gl/maps/jcikLm8hnPqoSxr78"
        },
      ]
    }
  },
}
</script>
<style lang="scss" scoped>
.location {
    border: 1px solid #d8d8d8;
    margin-bottom: 2em;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    border-radius: 4px;;
}
.location-image {
  width: 33%;
}
.location-image img{
  height: auto;
width: 100%;
  object-fit: cover;
}
.location-info {
  flex: 1;
  padding: 2em;
  line-height: 1.5;
}

.booked {
  background-color: #f4f4f4;
  color: #bebebe;
}

.booked a {
  display: none;
}

.location h4 {
  margin: 0 0 1em 0;
}

.location-links a {
  margin-top: 1em;
  margin-right: 2.5em;
}
@media screen and (max-width: 767px) {
  .location-links a {
  display: block;
  margin-top: 0;
  margin-right: 0;
}
.location {
  flex-direction: column;
}
.location-image {
  width: 100%;
}
}
</style>