// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDXmJKvVRSh8VPy9YPoIOudPvN36CRtank",
    authDomain: "fitzgerald-wedding.firebaseapp.com",
    projectId: "fitzgerald-wedding",
    storageBucket: "fitzgerald-wedding.appspot.com",
    messagingSenderId: "328000239718",
    appId: "1:328000239718:web:73af8dbc0c1d1619f495a7",
    measurementId: "G-EWLXD2E75Y"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);

if (window.location.hostname == "thefitzgeraldwedding.com") {
    getAnalytics(app);
}
